import { Box, CircularProgress } from '@mui/material';
import AddDocument from 'features/master-document/add';
import EditProject from 'features/projects/edit';
import React, { Suspense } from 'react';
import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';

const MainLayout = React.lazy(() => import('layout/MainLayout'));
const Home = React.lazy(() => import('features/home'));
const Login = React.lazy(() => import('features/auth/login'));
const NotFound = React.lazy(() => import('features/not-found'));
const InternalServerError = React.lazy(() => import('features/internal-server-error'));
const ResetPassword = React.lazy(() => import('features/auth/reset-password'));
const ForgotPassword = React.lazy(() => import('features/auth/forgot-password'));
const CheckMail = React.lazy(() => import('features/auth/check-mail'));
const MasterDocument = React.lazy(() => import('features/master-document'));
const ProjectList = React.lazy(() => import('features/projects'));
const AddProject = React.lazy(() => import('features/projects/add'));
const Profile = React.lazy(() => import('features/user/profile'));
const Company = React.lazy(() => import('features/company'));
const UserList = React.lazy(() => import('features/user'));
const AddUser = React.lazy(() => import('features/user/add'));
const RoleList = React.lazy(() => import('features/role'));
const AddRole = React.lazy(() => import('features/role/add'));
const EditRole = React.lazy(() => import('features/role/edit'));
const UserGroupList = React.lazy(() => import('features/user-group'));
const AddUserGroup = React.lazy(() => import('features/user-group/add'));
const EditUserGroup = React.lazy(() => import('features/user-group/edit'));

function RequireAuth() {
  const location = useLocation();
  // if (!auth.user.name) {
  //   return <Navigate to="/login" state={{ from: location }} />;
  // }

  return <Outlet />;
}

function MainRoutes() {
  return (
    <Suspense
      fallback={
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      }
    >
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/check-mail" element={<CheckMail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route element={<MainLayout />}>
          <Route element={<RequireAuth />}>
            <Route path="/master-document">
              <Route path="/master-document/list" element={<MasterDocument />} />
              <Route path="/master-document/add" element={<AddDocument />} />
            </Route>

            <Route path="/project">
              <Route path="/project/list" element={<ProjectList />} />
              <Route path="/project/add" element={<AddProject />} />
              <Route path="/project/edit/:id" element={<EditProject />} />
            </Route>

            <Route path="/company">
              <Route path="/company/info" element={<Company />} />
            </Route>

            <Route path="/user">
              <Route path="/user/list" element={<UserList />} />
              <Route path="/user/add" element={<AddUser />} />
              <Route path="/user/profile" element={<Profile />} />
            </Route>

            <Route path="/user-group">
              <Route path="/user-group/add" element={<AddUserGroup />} />
              <Route path="/user-group/edit/:id" element={<EditUserGroup />} />
              <Route path="/user-group/list" element={<UserGroupList />} />
            </Route>

            <Route path="/role">
              <Route path="/role/add" element={<AddRole />} />
              <Route path="/role/edit/:id" element={<EditRole />} />
              <Route path="/role/list" element={<RoleList />} />
            </Route>
          </Route>
        </Route>
        <Route path="/internal-server-error" element={<InternalServerError />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default MainRoutes;
