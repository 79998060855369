import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { FetchArgs } from '@reduxjs/toolkit/query';
import { getCookie } from 'utils/helper';
import { logOut } from 'features/auth/auth-slice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  // timeout: 20000,
  prepareHeaders: (headers, { getState }) => {
    const access = getCookie('accessToken') || '';
    if (access) {
      headers.set('authorization', `Bearer ${access}`);
    }
    return headers;
  }
});

const baseQueryWithReauth = async (args: string | FetchArgs, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.meta?.response?.status === 401) {
    api.dispatch(logOut());

    if (!window.location.href.includes('/login')) window.location.pathname = '/login';
  }

  if (result.error && result.meta?.response?.status === 500) {
    window.location.pathname = '/internal-server-error';
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [],
  endpoints: () => ({})
});
