import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ILoginResponse } from 'models/auth';
import { removeCookie } from 'utils/helper';

interface IState {
  fullName: string;
  roles: string[];
}

const initialState = {
  fullName: '',
  roles: []
} as IState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserAction: (state, action: PayloadAction<ILoginResponse>) => {
      state.fullName = action.payload.fullName;
      state.roles = action.payload.roles;
    },
    logOut: (state) => {
      removeCookie('accessToken');
    }
  }
});

export const { setUserAction, logOut } = authSlice.actions;
export default authSlice.reducer;

export const selectUser = (state: RootState) => state.authSlice;
