import { useNavigate, useParams } from 'react-router-dom';
import {
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Button,
  Autocomplete,
  Box,
  TextField,
  Typography,
  Chip
} from '@mui/material';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, useForm } from 'react-hook-form';
import Joi from 'joi';
import MainCard from 'components/MainCard';
import { openSnackbar } from 'store/reducers/snackbar';
import { dispatch } from 'store';
import { IEditProject } from 'models/project';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Add } from 'iconsax-react';
import { useEditProjectMutation, useLazyGetProjectQuery } from '../project-api-slice';
import { useEffect } from 'react';

const EditProject = () => {
  const navigate = useNavigate();
  const [postData, { error, isError }] = useEditProjectMutation();
  const { id } = useParams();
  const [getProject, { data: project }] = useLazyGetProjectQuery();

  const GetProject = async () => {
    try {
      if (id !== undefined) {
        await getProject(+id).unwrap();
      }
    } catch (error) {}
  };

  const schema = Joi.object({
    id: Joi.any().optional(),
    name: Joi.string().required().messages({
      'string.empty': 'required'
    }),
    execution: Joi.string().required().messages({
      'string.empty': 'required'
    }),
    startDate: Joi.number().required().messages({
      'any.empty': 'required'
    }),
    endDate: Joi.number().required().messages({
      'any.empty': 'required'
    }),
    description: Joi.string().optional(),
    disciplines: Joi.string().required().messages({
      'string.empty': 'required'
    }),
    documentDisciplines: Joi.array().items(Joi.string().trim().required().max(50)).required()
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm<IEditProject>({
    resolver: joiResolver(schema)
  });

  useEffect(() => {
    GetProject();
    return () => {};
  }, [id]);

  useEffect(() => {
    if (project) {
      setValue('id', project?.id);
      setValue('name', project?.name);
      setValue('execution', project?.execution);
      setValue('startDate', project?.startDate);
      setValue('endDate', project?.endDate);
      setValue('description', project?.description);
      setValue('disciplines', project?.disciplines);
      setValue('documentDisciplines', project?.documentDisciplines);
    }
    return () => {};
  }, [setValue, project]);

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const handleStartDateChange = (date: any) => {
    const epochDate = date ? date.getTime() : null;
    setValue('startDate', epochDate);
  };

  const handleEndDateChange = (date: any) => {
    const epochDate = date ? date.getTime() : null;
    setValue('endDate', epochDate);
  };

  const onSubmit = async () => {
    try {
      await postData(getValues()).unwrap();

      dispatch(
        openSnackbar({
          open: true,
          message: 'success',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      navigate('/project/list');
    } catch (err) {}
  };

  return (
    <MainCard title="Edit Document">
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3.5}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="name">Project Name</InputLabel>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput id="name" type="text" placeholder="Project Name" fullWidth error={Boolean(errors?.name)} {...field} />
                )}
              />
              {errors?.name && <FormHelperText error>{errors?.name?.message}</FormHelperText>}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="execution">Project Execution</InputLabel>
              <Controller
                name="execution"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="execution"
                    type="text"
                    placeholder="Project Execution"
                    fullWidth
                    error={Boolean(errors?.execution)}
                    {...field}
                  />
                )}
              />
              {errors?.execution && <FormHelperText error>{errors?.execution?.message}</FormHelperText>}
            </Stack>
          </Grid>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel>Planned Start Date</InputLabel>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      slotProps={{ textField: { fullWidth: true } }}
                      format="yyyy/MM/dd"
                      onChange={(date) => handleStartDateChange(date)}
                    />
                  )}
                />
                {errors.startDate && <FormHelperText error>{errors?.startDate?.message}</FormHelperText>}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel>Planned End Date</InputLabel>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      slotProps={{ textField: { fullWidth: true } }}
                      format="yyyy/MM/dd"
                      onChange={(date) => handleEndDateChange(date)}
                    />
                  )}
                />
                {errors.endDate && <FormHelperText error>{errors?.endDate?.message}</FormHelperText>}
              </Stack>
            </Grid>
          </LocalizationProvider>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="disciplines">Disciplines</InputLabel>
              <Controller
                name="disciplines"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    id="disciplines"
                    type="text"
                    placeholder="Disciplines"
                    fullWidth
                    error={Boolean(errors?.disciplines)}
                    {...field}
                  />
                )}
              />
              {errors.disciplines && <FormHelperText error>{errors?.disciplines?.message}</FormHelperText>}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="documentDisciplines">Document Disciplines</InputLabel>
              <Controller
                name="documentDisciplines"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    fullWidth
                    autoHighlight
                    freeSolo
                    disableCloseOnSelect
                    options={[]}
                    onBlur={field.onBlur}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {!field.value.some((v) => option.includes(v)) ? `Add "${option}"` : option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="documentDisciplines"
                        placeholder="Write your document disciplines"
                        error={Boolean(errors.documentDisciplines)}
                        helperText={errors.documentDisciplines?.message}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        let error = false;
                        if (errors.documentDisciplines && typeof errors.documentDisciplines?.message !== 'string') {
                          if (typeof errors.documentDisciplines[index] === 'object') error = true;
                        }

                        return (
                          <Chip
                            {...getTagProps({ index })}
                            variant="combined"
                            color={error ? 'error' : 'secondary'}
                            label={
                              <Typography variant="caption" color="secondary.dark">
                                {option}
                              </Typography>
                            }
                            deleteIcon={<Add style={{ fontSize: '0.875rem', transform: 'rotate(45deg)' }} />}
                            size="small"
                          />
                        );
                      })
                    }
                  />
                )}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="description">Description</InputLabel>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="description"
                    type="text"
                    placeholder="Description"
                    fullWidth
                    error={Boolean(errors?.description)}
                    {...field}
                  />
                )}
              />
              {errors?.description && <FormHelperText error>{errors?.description?.message}</FormHelperText>}
            </Stack>
          </Grid>

          {isError && (
            <Grid item xs={12} sm={6}>
              <FormHelperText error>
                {
                  // @ts-ignore
                  error?.data?.message
                }
              </FormHelperText>
            </Grid>
          )}

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-start">
              <Button disableElevation disabled={false} type="submit" variant="contained" color="primary" sx={{ minWidth: 120 }}>
                submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};

export default EditProject;
