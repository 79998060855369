import { apiSlice } from 'store/api-slice';
import {
  IAddProject,
  IEditProject,
  IFilterProject,
  IPaginateUserPermissionRequest,
  IProject,
  IProjectList,
  IUserPermissionRequest
} from 'models/project';
import APIs from 'utils/APIs';

export const projectApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    newProject: builder.mutation<any, IAddProject>({
      query: (body) => ({
        url: `${APIs.project.add_project}`,
        method: 'POST',
        body
      })
    }),
    editProject: builder.mutation<any, IEditProject>({
      query: (body) => ({
        url: `${APIs.project.edit_project}`,
        method: 'POST',
        body
      })
    }),
    getProject: builder.query<IProject, number>({
      query: (id) => `${APIs.project.fetch_project}?Id=${id}`,
      transformResponse: ({ data }) => data
    }),
    listProject: builder.query<IProjectList, IFilterProject>({
      query: (filter) => `${APIs.project.list_project}?pageNumber=${filter.pageNumber}&pageSize=${filter.pageSize}`,
      transformResponse: ({ data }) => data
    }),
    addUserPermission: builder.mutation<any, IUserPermissionRequest>({
      query: (body) => ({
        url: `${APIs.project.add_user_permission}`,
        method: 'POST',
        body
      })
    }),
    getUserPermission: builder.query<any, IPaginateUserPermissionRequest>({
      query: (filter) =>
        `${APIs.project.all_user_permission}?ProjectId=${filter.projectId}&pageNumber=${filter.pageNumber}&pageSize=${filter.pageSize}`,
      transformResponse: ({ data }) => data
    }),
    deleteUserPermission: builder.mutation<any, number>({
      query: (id) => ({
        url: `${APIs.project.delete_user_permission}`,
        method: 'POST',
        body: {
          id
        }
      })
    })
  })
});

export const {
  useNewProjectMutation,
  useEditProjectMutation,
  useLazyGetProjectQuery,
  useLazyListProjectQuery,
  useAddUserPermissionMutation,
  useLazyGetUserPermissionQuery,
  useDeleteUserPermissionMutation
} = projectApiSlice;
