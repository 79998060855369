import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormHelperText, Grid, InputLabel, OutlinedInput, Stack, Button, FormControl, Select, MenuItem } from '@mui/material';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, useForm } from 'react-hook-form';
import Joi from 'joi';
import MainCard from 'components/MainCard';
import { openSnackbar } from 'store/reducers/snackbar';
import { dispatch } from 'store';
import AvatarUpload from 'components/third-party/dropzone/Avatar';
import { IAddDocument } from 'types/master-document';
import { convertObjectToFormData } from 'utils/helper';
import { DocumentUpload } from 'iconsax-react';

const AddDocument = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  //   const { data: roles = [] } = useRole_listQuery();
  //   const [postData, { data, error, isError, isLoading }] = useAdd_userMutation();

  const schema = Joi.object({
    title: Joi.string().required().messages({
      'string.empty': 'required'
    }),
    documentNumber: Joi.string().required().messages({
      'string.empty': 'required'
    }),
    description: Joi.any().optional(),
    discipline: Joi.number().required().messages({
      'any.required': 'required'
    }),
    uploadFile: Joi.any().optional()
    // .custom((value, helpers) => {
    //   const allowedFileTypes = ['image/jpeg', 'image/png'];
    //   if (!allowedFileTypes.includes(value.type)) {
    //     return helpers.error('any.custom');
    //   }
    //   return value;
    // })
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<IAddDocument>({
    resolver: joiResolver(schema)
  });

  const onSubmit = async () => {
    try {
      const values = convertObjectToFormData(getValues());

      dispatch(
        openSnackbar({
          open: true,
          message: 'عملیات با موفقیت انجام شد.',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      // navigate('/user/list');
    } catch (err) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'خطا در عملیات.',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
    }
  };

  return (
    <MainCard title="Create New Document">
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3.5}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Document Title</InputLabel>
              <Controller
                name="title"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput id="title" type="text" placeholder="Document Title" fullWidth error={Boolean(errors?.title)} {...field} />
                )}
              />
              {errors?.title && <FormHelperText error>{errors?.title?.message}</FormHelperText>}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="documentNumber">Document Number</InputLabel>
              <Controller
                name="documentNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="documentNumber"
                    type="text"
                    placeholder="Document Numbering Format"
                    fullWidth
                    error={Boolean(errors?.documentNumber)}
                    {...field}
                  />
                )}
              />
              {errors?.documentNumber && <FormHelperText error>{errors?.documentNumber?.message}</FormHelperText>}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="description">Document Description</InputLabel>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="description"
                    type="text"
                    placeholder="Document Description"
                    error={Boolean(errors.description)}
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.description && <FormHelperText error>{errors?.description?.message}</FormHelperText>}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="discipline">Document Disciplines</InputLabel>
              <Controller
                name="discipline"
                control={control}
                render={({ field }) => (
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select {...field} error={Boolean(errors.discipline)}>
                      <MenuItem value="">
                        <em>Select Discipline</em>
                      </MenuItem>
                      {/* {roles &&
                        roles.map((item: any, index: number) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))} */}
                    </Select>
                  </FormControl>
                )}
              />
              {errors.discipline && <FormHelperText error>{errors?.discipline?.message}</FormHelperText>}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <Button
                variant="outlined"
                fullWidth
                color="secondary"
                startIcon={<DocumentUpload size="32" color="#FF8A65" variant="TwoTone" />}
              >
                Add Files
              </Button>
            </Stack>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <Controller
                name="uploadFile"
                control={control}
                render={({ field }) => (
                  <AvatarUpload
                    {...field}
                    setFieldValue={(filename, value) => setValue('uploadFile', value)}
                    file={field.value}
                    error={Boolean(errors.uploadFile)}
                  />
                )}
              />
            </Stack>
          </Grid> */}
          {/* 
          {isError && (
            <Grid item xs={12} sm={6}>
              <FormHelperText error>
                {
                  // @ts-ignore
                  error?.data?.message
                }
              </FormHelperText>
            </Grid>
          )} */}

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-start">
              <Button disableElevation disabled={false} type="submit" variant="contained" color="primary" sx={{ minWidth: 120 }}>
                submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};

export default AddDocument;
