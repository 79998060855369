/* eslint-disable import/no-anonymous-default-export */
export default {
  auth: {
    login: 'User/GetToken',
    forgot_password: 'User/ForgotPassword',
    reset_password: 'User/ConfirmForgotPassword'
    // logout: 'logout/'
  },
  master_document: {
    add: '/auth-service/v1.0/generate/sms'
  },
  project: {
    add_project: 'Project/CreateProject',
    edit_project: 'Project/EditProject',
    fetch_project: 'Project/GetProjectById',
    list_project: 'Project/GetAllProject',
    add_user_permission: 'Project/CreateProjectPermission',
    all_user_permission: 'Project/GetAllProjectPermission',
    delete_user_permission: 'Project/DeleteProjectPermission'
  },
  role: {
    list: 'Role/GetAllRole',
    createRole: 'Role/CreateRole',
    editRole: 'Role/EditRole',
    assignRoleToPermission: 'Role/AssignRolePermission',
    deleteRolePermission: 'Role/DeleteRolePermission',
    getAllRolePermission: 'Role/GetAllRolePermission',
    getAllPermission: 'Role/GetAllPermission',
    getRole: 'Role/GetRoleById'
  },
  user: {
    createUser: 'User/RegisterUser',
    userProfile: 'User/GetUserProfile',
    editUserProfile: 'User/ChangeUserProfile',
    changePassword: 'User/ChangePassword',
    list: 'User/GetAllUsers',
    getUser: 'User/GetUser'
  },
  userGroup: {
    list: 'UserGroup/GetAllUserGroup',
    createUserGroup: 'UserGroup/CreateUserGroup',
    editUserGroup: 'UserGroup/EditUserGroup',
    getUserGroup: 'UserGroup/GetUserGroupById'
  },
  company: {
    getCompany: 'Company/GetCompanyInfo',
    editCompany: 'Company/EditCompanyInfo'
  }
};
